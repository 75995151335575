import React ,{Component} from 'react';
// import test from './test.gif';
import './App.css';
import Timer from './components/Timer'
// import Map from './components/Map'
// import surprise from './assets/surprise.jpg'
import beian from './assets/ghs.png'

class App extends Component {
  constructor (props,context){
    super(props,context);
    this.state = {
      isShown : false,
      width: 0, 
      height: 0 
    }
    
  }
  showSurprise = ()=>{
    this.setState({
      isShown:!this.state.isShown
    });
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }
  
  updateWindowDimensions() {
    this.setState({ 
      width: window.innerWidth, 
      height: window.innerHeight 
    });
  }
  // goWechat(){
  //   this.props.history.push('/weChat')
  // }
  goQuestions(){
    this.props.history.push('./mi')
  }
  render(){
    return (
      <div className="App">
        <div className="frontPage" style={{height:this.state.height+'px'}}>
          <h1 className="intro">
            明妆，
            <br/>
            能在这茫茫人海之中，
            <br/>
            遇见你，
            <br/>
            是我三生有幸。
          </h1>
          <h2 className="desc">余生还长，愿于风雨共同舟</h2>
          {/* <div className="blank">
            {
              this.state.isShown ? (<div className="surprise surpriseAnimation">
                          <img src={surprise} onClick={this.goWechat.bind(this)}/>
                        </div>) : null
            }
          </div> */}
          <div className="timer" onClick={()=>this.showSurprise()}>
            <Timer></Timer>
          </div>
          <div className="beian">
            {/* <a href='http://beian.miit.gov.cn' target='_blank' >赣ICP备20002767号</a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36082102000019">
              <img src={beian}/>
              &nbsp; 
              <text>赣公网安备 36082102000019号</text>
            </a> */}
            Copyright © 2010-2020 明妆.我爱你 All Rights Reserved.
        </div>
        </div>
        
        
        {/* <div className="bottom">
          <Map></Map>
        </div> */}
        
      </div>
    );
  }
}

export default App;
