import React, {Component} from 'react'
import { Route, BrowserRouter } from 'react-router-dom';
import App from './App';
// import Answer from './AppSurvey/components/Answer'
// import MyAnswers from './AppSurvey/components/MyAnswers'
// import AppSurvey from './AppSurvey/App';
// import Home from './AppSurvey/Home';
// import Questionnaire from './AppSurvey/components/Questionnaire'
// import Wechat from "./components/WechatHistory"
// import Secret from "./components/Secret"
// import AnimatedRouter from 'react-animated-router'; //导入我们的的AnimatedRouter组件
import 'react-animated-router/animate.css'; //导入默认的切换动画样式，如果需要其它切换样式，可以导入自己的动画样式定义文件
// import Qixi from './components/Qixi2020'
// import Message from './components/Message'

class RouterView extends Component {
  render() {
    return (
      <BrowserRouter>
                  <Route exact path="/" component={App}/>
        {/* <AnimatedRouter> */}

            {/* <Route  path="/survey" component={Home}/>
            <Route path='/survey/app' component={AppSurvey} />
            <Route path='/survey/questionnaire' component={Questionnaire} />
            <Route path='/survey/answer' component={Answer} />
            <Route path='/survey/myAnswers' component={MyAnswers} /> */}
            {/* <Route path='/wechat' component={Wechat}/> */}
            {/* <Route path='/mi' component={Secret}/> */}
            {/* <Route path='/七夕_2020' component={Qixi}/> */}
            {/* <Route path='/message' component={Message}/> */}
        {/* </AnimatedRouter> */}
      </BrowserRouter>
    )
  }
}

export default RouterView