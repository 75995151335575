import React ,{Component} from 'react'  
import './Timer.css'
import { Progress } from 'antd';
var moment = require('moment-timezone');
moment.tz.setDefault("Asia/Shanghai");

class Timer extends Component {
  constructor(props,context){
    super(props,context)
    this.state = {
      firstDate: moment("2019-12-24 20:05:00"),
      firstDateVn: moment("2019-12-24 19:05:00").tz("Asia/Ho_Chi_Minh"),
      diffDays:"",
      diffMins:"",
      diffSecs:"",
      diffMonths:"",
      diffYears:"",
      now:"",
      mode:0
    }
  }
  componentDidMount(){
    // var old =  moment("2019-12-27 00:43")
    // old.tz("Asia/Shanghai").format('ha z')
    // this.state.firstDate = old

    console.log(this.state.firstDate)
    setInterval(() => {
      var now = moment('2024-04-21 22:00:00')
      var secs = now.diff(this.state.firstDate,"seconds");
      var years = now.diff(this.state.firstDate,"years",true).toFixed(2);
      var months = now.diff(this.state.firstDate,"months",true).toFixed(1);
      this.setState({
        diffYears:years,
        diffMonths:months,
        diffDays:Math.floor(secs/(24*60*60)),
        diffHrs:Math.floor(secs/(60*60)%24),
        diffMins:Math.floor(secs/60%60),
        diffSecs:Math.floor(moment('2024-04-21 22:00:00').diff(this.state.firstDate,"seconds")%(60)),
        now:now.format("YYYY-MM-DD HH:mm:ss")
      })
      // console.log(now)
    }, 1000);
  }
  onChangeMode(){
    this.setState({
      mode:(Math.floor(this.state.mode)+1)%3
    })
  }
  render () {
    switch (this.state.mode) {
      case 0:
        return (
          <div className='time-box' onClick={this.onChangeMode.bind(this)}>
            <div className="time-progress" >
              <div className="progress hours">
                <Progress type="circle"
                  percent={this.state.diffHrs/24*100}
                  format={percent=> ' '}
                  strokeColor={{
                    '0%':"#ABDCFF",
                    '100%':"#0396FF"
                  }}
                  width="160px"
                  trailColor="#E6E6E6"
                  strokeWidth="5"
                />
              </div>
              <div className="progress mins">
                <Progress type="circle"
                  percent={this.state.diffMins/60*100}
                  format={percent=> ' '}
                  strokeColor={{
                    '0%':"#90F7EC",
                    '100%':"#32CCBC"
                  }}
                  trailColor="#E6E6E6"
                  width="140px"
                />
              </div>
              <div className="progress secs">
                <Progress type="circle" 
                  percent={this.state.diffSecs/60*100} 
                  format={percent => this.state.diffDays ? `${this.state.diffDays} 天 ` : ''} 
                  strokeColor={{
                    '0%': '#FDEB71',
                    '100%': '#F8D800',
                  }}  
                  trailColor="#E6E6E6"
                />
              </div>
            </div>
          </div>)
      case 1:
        return (
          <div className='time-box' onClick={this.onChangeMode.bind(this)}>
            <div className="time-progress">
              <div className="progress">
                <Progress type="circle"
                  percent={this.state.diffMonths/12*100}
                  format={percent => `${this.state.diffMonths} 月`} 
                  strokeColor={{
                    '0%':"#90F7EC",
                    '100%':"#32CCBC"
                  }}
                  trailColor="#E6E6E6"
                  width="142px"
                />
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div className='time-box' onClick={this.onChangeMode.bind(this)}>
            <div className="time-progress">
              <div className="progress mins">
                <Progress type="circle"
                  percent={this.state.diffYears*100}
                  format={percent => `${this.state.diffYears} 年`}
                  strokeColor={{
                    '0%':"#90F7EC",
                    '100%':"#32CCBC"
                  }}
                  trailColor="#E6E6E6"
                  width="142px"
                />
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className='time-box' onClick={this.onChangeMode.bind(this)}>
            <div className="time-text">
              {this.state.diffDays} days {this.state.diffHrs}:{this.state.diffMins}:{this.state.diffSecs}
            </div>
          </div>
        )
    }
  }
}

export default Timer;
